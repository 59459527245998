<template>
  <div class="brandComWrap">
    <div class=" filterBoxFixed">
      <div class="sovTitle">
        <div class="filter">
          <div class="left_block">
            <div class="title_block">
              <p>品牌</p>
            </div>
            <div class="info_block" v-for="(item, index) in brandNameLabelList" :key="index">
              <div class="logo_block">
                <img :src="logo[index]"/>
              </div>
              <p><span class="ellipsis">{{
                  item}}</span></p>
            </div>
            <div class="date_block">
              <span>时间范围</span>
              <el-date-picker
                  v-model="timeRangeVal"
                  type="monthrange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  value-format="YYYY-MM"
                  :disabled-date="disabledDate"
                  :clearable="false"
                  size="small"
              >
              </el-date-picker>
            </div>
            <div>
              <!--        <svg-icon class-name="rili" class="riliClass"></svg-icon>-->
            </div>
            <!--    <el-button size="mini" @click="forBack" class="returnBtn">返回</el-button>-->
          </div>
        </div>
      </div>
    </div>
    <div style="width:80%;">
      <div class="publicStyle" style="margin-top:0px;">
        <p class="publicTitleStyle">品牌声量概览
          <div>
            <svg-icon
                icon-class="downloadIcon"
                class-name="downloadIcon"
            ></svg-icon>
          <el-button
              :loading="brandsGeneralViewLoad"
              class="noborderBtn"
              type="text"
              style="margin-right:0;color:#000;"
              @click="downloadGeneralView"
          >
            下载
          </el-button>
          </div>
        </p>
        <div style="margin-top: 10px">
          <el-table
              v-loading="generalViewLoading"
              :header-cell-style="generalViewTableHeadClass"
              :row-class-name="rowStyleFunc"
              :data="generalViewTableData"
          >
            <el-table-column prop="name" align="center" label="品牌">
            </el-table-column>
            <el-table-column prop="exposure" align="center" label="曝光量">
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content>全部发帖的去水曝光量</template>
                  <div>曝光量</div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{ row.exposure === "--" ? "--" : sliceDecimal(row.exposure) }}
              </template>
            </el-table-column>
            <el-table-column prop="engagementQs" align="center" label="互动量">
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content>全部发帖的去水互动量</template>
                  <div>互动量</div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{
                  row.engagementQs === "--"
                      ? "--"
                      : sliceDecimal(row.engagementQs)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="pgcNum" align="center" label="PGC发帖数量">
              <template #default="{ row }">
                {{ row.pgcNum === "--" ? "--" : sliceDecimal(row.pgcNum) }}
              </template>
            </el-table-column>
            <el-table-column prop="ugcNum" align="center" label="UGC发帖数量">
              <template #default="{ row }">
                {{ row.ugcNum === "--" ? "--" : sliceDecimal(row.ugcNum) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="pgcExposurePct"
                align="center"
                label="PGC曝光占比"
            >
              <template #header>
                <div>
                  <el-tooltip class="item" effect="dark" placement="top">
                    <template #content
                    >PGC发帖对应的去水曝光量占全部发帖去水曝光量的比例</template
                    >
                    <div>
                      <p>PGC曝光占比</p>
                    </div>
                  </el-tooltip>
                </div>
              </template>
              <template #default="{ row }">
                {{
                  row.pgcExposurePct === "--"
                      ? "--"
                      : sliceDecimal(row.pgcExposurePct)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="pgcExposure" align="center" label="PGC曝光量">
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content>PGC发帖去水曝光量</template>
                  <div>
                    <p>PGC曝光量</p>
                  </div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{
                  row.pgcExposure === "--" ? "--" : sliceDecimal(row.pgcExposure)
                }}
              </template>
            </el-table-column>
            <el-table-column
                prop="pgcEngagementQs"
                align="center"
                label="PGC互动量"
            >
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content>PGC发帖去水互动量</template>
                  <div>
                    <p>PGC互动量</p>
                  </div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{
                  row.pgcEngagementQs === "--"
                      ? "--"
                      : sliceDecimal(row.pgcEngagementQs)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="cost" align="center" label="投放费用">
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content
                  >PGC发帖对应的KOL刊例价加总，并由算法校验计算得出</template
                  >
                  <div>投放费用</div>
                </el-tooltip>
              </template>
              <template #default="scoped">
                {{
                  distribute.radio === "percent"
                      ? scoped.row.cost === "--"
                          ? "--"
                          : "¥" + sliceDecimal(scoped.row.cost)
                      : "¥" + sliceDecimal(scoped.row.cost)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="cpm" align="center" label="CPM">
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content
                  >PGC发帖的千次曝光成本，基于去水曝光量计算</template
                  >
                  <div>CPM</div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{ row.cpm === "--" ? "--" : "¥" + sliceDecimal(row.cpm) }}
              </template>
            </el-table-column>
            <el-table-column prop="cpe" align="center" label="CPE">
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content
                  >PGC发帖的单次互动成本，基于去水互动量计算</template
                  >
                  <div>CPE</div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{ row.cpe === "--" ? "--" : "¥" + sliceDecimal(row.cpe) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="publicStyle ta">
        <p class="publicTitleStyle">投放TA触达效率
          <div>

            <span
                :style="{
              fontSize:'12px',
              marginRight: '10px',
            }"
            >目标人群</span
            >
            <el-select
                v-model="segValue"
                size="mini"
                class="m-2 autoWidth"
                @change="changeSeg"
                placeholder="请选择人群"
            >
              <template #prefix>
                {{ crowdListComputed.filter(i => i.key === segValue)[0].name }}
              </template>
              <el-option
                  v-for="item in crowdListComputed"
                  :key="item.key"
                  :label="item.name"
                  :value="item.key"
              />
            </el-select>
          </div></p>
        <div style="margin-top: 10px">
          <el-table
              v-loading="taLoading"
              :header-cell-style="generalViewTableHeadClass"
              :row-class-name="rowStyleFunc"
              :data="taTableData"
              :tree-props="{ children: 'details' }"
              row-key="id"
          >
            <el-table-column width="120" prop="name" align="left" label="品牌">
            </el-table-column>
            <el-table-column
                prop="taExposurePct"
                align="center"
                label="TA曝光占比"
            >
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content
                  >PGC发帖触达TA去水曝光量占全部PGC发帖去水曝光量的比例</template
                  >
                  <div>TA曝光占比</div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{ row.taExposurePct === "--" ? "--" : row.taExposurePct }}
              </template>
            </el-table-column>
            <el-table-column
                prop="taExposure"
                align="center"
                label="触达TA总曝光量"
            >
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content>PGC发帖触达TA去水曝光量</template>
                  <div>触达TA总曝光量</div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{
                  row.taExposure === "--" ? "--" : sliceDecimal(row.taExposure)
                }}
              </template>
            </el-table-column>
            <el-table-column
                prop="taEngagementQs"
                align="center"
                label="触达TA总互动量"
            >
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content>PGC发帖触达TA去水互动量</template>
                  <div>触达TA总互动量</div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{
                  row.taEngagementQs === "--"
                      ? "--"
                      : sliceDecimal(row.taEngagementQs)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="taCpm" align="center" label="TA CPM">
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content
                  >PGC发帖在TA人群中的千次曝光成本，基于去水曝光量计算</template
                  >
                  <div>TA CPM</div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{ row.taCpm === "--" ? "--" : "¥" + sliceDecimal(row.taCpm) }}
              </template>
            </el-table-column>
            <el-table-column prop="taCpe" align="center" label="TA CPE">
              <template #header>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content
                  >PGC发帖在TA人群中的单次互动成本，基于去水互动量计算</template
                  >
                  <div>TA CPE</div>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{ row.taCpe === "--" ? "--" : "¥" + sliceDecimal(row.taCpe) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="publicStyle distribute">
        <p class="publicTitleStyle">品牌KOL投放结构
          <el-radio-group
              v-model="field"
              size="mini"
              :class="{ cancelMargin: !isShowCategory }"
              style="margin-bottom: 10px"
          >
            <el-radio-button label="pgc_num">发帖数量</el-radio-button>
            <el-radio-button label="cost">投放费用</el-radio-button>
          </el-radio-group>
        </p>

        <div class="btnGroupWrap">

          <span
              style="float: right"
              @click="handleExpansion"
              :class="['spandAll', { isExpandClass: !expansion.includes(false) }]"
          >
          <!-- 全部展开 -->
          {{ expansion.includes(false) ? "全部展开" : "全部收起" }}
          <i
              class="expandAllIclass el-icon-arrow-down"
              :class="expansion.includes(false) ? ['rotate'] : 'el-icon-arrow-up'"
          ></i>
        </span>
          <el-radio-group
              v-model="distribute.radio"
              style="
            margin-right: 10px;
            float: right;
            position: relative;
            top: -5px;
          "
          >
            <el-radio label="percent">占比</el-radio>
            <el-radio label="amount">数量</el-radio>
          </el-radio-group>
        </div>
        <div class="distributeTable">
          <el-table
              v-loading="distributeLoading"
              row-key="id"
              :tree-props="{ children: 'details' }"
              ref="treeTable"
              :data="distributeTableData"
              :row-class-name="distributeRowStyleFunc"
              @expand-change="expandChange"
          >
            <el-table-column prop="name" align="left" width="120" label="品牌">
            </el-table-column>
            <el-table-column
                class="wechat"
                prop="wechat"
                align="center"
                label="微信"
            >
              <template #default="{ row }">
                {{
                  distribute.radio === "percent"
                      ? row.wechat === null
                          ? "--"
                          : row.wechat
                      : row.wechat === "--"
                          ? 0
                          : field === "cost"
                              ? "￥" + sliceDecimal(row.wechat)
                              : sliceDecimal(row.wechat)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="weibo" align="center" label="微博">
              <template #default="{ row }">
                {{
                  distribute.radio === "percent"
                      ? row.weibo === null
                          ? "--"
                          : row.weibo
                      : row.weibo === "--"
                          ? 0
                          : field === "cost"
                              ? "￥" + sliceDecimal(row.weibo)
                              : sliceDecimal(row.weibo)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="douyin" align="center" label="抖音">
              <template #default="{ row }">
                {{
                  distribute.radio === "percent"
                      ? row.douyin === null
                          ? "--"
                          : row.douyin
                      : row.douyin === "--"
                          ? 0
                          : field === "cost"
                              ? "￥" + sliceDecimal(row.douyin)
                              : sliceDecimal(row.douyin)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="xiaohongshu" align="center" label="小红书">
              <template #default="{ row }">
                {{
                  distribute.radio === "percent"
                      ? row.xiaohongshu === null
                          ? "--"
                          : row.xiaohongshu
                      : row.xiaohongshu === "--"
                          ? 0
                          : field === "cost"
                              ? "￥" + sliceDecimal(row.xiaohongshu)
                              : sliceDecimal(row.xiaohongshu)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="bilibili" align="center" label="B站">
              <template #default="{ row }">
                {{
                  distribute.radio === "percent"
                      ? row.bilibili === null
                          ? "--"
                          : row.bilibili
                      : row.bilibili === "--"
                          ? 0
                          : field === "cost"
                              ? "￥" + sliceDecimal(row.bilibili)
                              : sliceDecimal(row.bilibili)
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-card
          :body-style="{
        height: '460px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }"
          style="margin-bottom: 20px; border-radius: 10px"
      >
        <p class="item_title" style="display: flex;justify-content: space-between;align-items: center">品牌表现趋势
          <el-radio-group
              v-model="echartsTime"
              size="mini"
              style="margin-right: 29px; text-align: right"
          >
            <el-radio-button label="day">天</el-radio-button>
            <el-radio-button label="week">周</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
        </p>
        <div style="display: flex; justify-content: space-between;align-items: center;">
          <div style="flex: 1; display: flex; align-items: flex-start">
            <el-select
                v-model="campaignFilter"
                multiple
                placeholder="请选择"
                size="mini"
                collapse-tags
                @change="handleFilter"
            >
              <el-option
                  v-for="item in campaignListOption"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <div
                style="flex: 1; display: flex; flex-wrap: wrap; margin-left: 20px"
            >
              <legend-com
                  v-for="legend in echartLegend"
                  :key="legend.index"
                  :data="legend"
              />
            </div>
          </div>
          <el-radio-group
              v-model="distributeCard.radio"
              size="mini"
              style="margin-right: 10px; position: relative;"
          >
            <el-radio label="exposure">曝光量</el-radio>
            <el-radio label="engagement">互动量</el-radio>
            <el-radio label="pgcNum">投放文章数</el-radio>
            <el-radio label="cost">投放费用</el-radio>
          </el-radio-group>
        </div>
        <line-bar
            :xAxis="echartxAxis"
            :series="echartSeries"
            :color="echartColor"
        />
      </el-card>
      <el-card
          :body-style="{
        height: '600px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }"
          style="margin-bottom: 20px; border-radius: 10px"
      >
        <p class="item_title">品牌人群画像对比</p>
        <div class="profileWrap">
          <div>
            <sovBrandCompareProfile
                :xAxisData="xAxisData"
                :profileData="arr1"
                :title="'性别占比'"
                :color="['rgb(83, 186, 210)', '#e893a8']"
                :legend="genderLegend"
                :ref="'compareProfile' + Math.random()"
            ></sovBrandCompareProfile>
          </div>
          <div>
            <sovBrandCompareProfile
                :xAxisData="xAxisData"
                :legend="ageLegend"
                :title="'年龄占比'"
                :profileData="arr"
                :ref="'compareProfile' + Math.random()"
            ></sovBrandCompareProfile>
          </div>
        </div>
        <div class="profileWrap">
          <div>
            <sovBrandCompareProfile
                :xAxisData="xAxisData"
                :profileData="cityArr"
                :title="'城市占比'"
                :color="['#32a3be', 'rgb(83, 186, 210)', '#e893a8', '#e37893']"
                :legend="cityLegend"
                :ref="'compareProfile' + Math.random()"
            ></sovBrandCompareProfile>
          </div>
          <div>
            <sovBrandCompareProfile
                :xAxisData="xAxisData"
                :legend="incomeLegend"
                :title="'收入水平占比'"
                :profileData="incomeArr"
                :ref="'compareProfile' + Math.random()"
            ></sovBrandCompareProfile>
          </div>
        </div>
      </el-card>
      <div class="publicStyle">
        <p class="publicTitleStyle">品牌PGC投放KOL内容
          <div class="rightWrap">
            <!-- <svg-icon
              class-name="iconuser"
              :style="{
                fontSize: '22px',
                color: '#dda634',
                verticalAlign: 'middle',
                marginRight: '10px',
              }"
            />
            <span
              :style="{
                marginRight: '10px',
              }"
              >目标人群</span
            >
            <el-select
              v-model="segValue"
              size="mini"
              class="m-2"
              @change="changeSeg"
              placeholder="请选择人群"
            >
              <el-option
                v-for="item in crowdListComputed"
                :key="item.key"
                :label="item.name"
                :value="item.key"
              />
            </el-select> -->
            <span
                :style="{
                fontSize:'12px',
                marginRight: '10px',
                flexShrink: 0
              }"
            >目标人群</span
            >
            <el-select
                v-model="segValue"
                size="mini"
                style="flex-shrink: 0;"
                class="m-2 autoWidth"
                @change="changeTopSeg"
                placeholder="请选择人群"
            >
              <template #prefix>
                {{ crowdListComputed.filter(i => i.key === segValue)[0].name }}
              </template>
              <el-option
                  v-for="item in crowdListComputed"
                  :key="item.key"
                  :label="item.name"
                  :value="item.key"
              />
            </el-select>
            <span class="searchText">查找产品/品类关键词</span>
            <el-input
                @keyup.enter="handleSearch"
                v-model="searchInput"
                placeholder="请输入内容"
            ></el-input>
            <!-- <span @click="searchBtn" class="btnPublic btnSearch">搜索</span> -->
            <el-button
                size="mini"
                @click="searchBtn"
                class="btnPublic"
                style="width: 64px"
            >搜索
            </el-button>
            <div style="flex-shrink: 0;">
              <svg-icon
                  icon-class="downloadIcon"
                  class-name="downloadIcon"
              ></svg-icon>
              <el-button
                  :loading="btnLoading"
                  class="noborderBtn"
                  type="text"
                  style="margin-right:0;color:#000;"
                  @click="downContent"
              >
                下载
              </el-button>
            </div>
          </div>
        </p>
        <div class="headBox">
            <el-radio-group size="mini" v-model="brandRadio">
              <el-radio-button
                  v-for="item in campaignList"
                  :key="item.label"
                  :label="item.value"
              >{{ item.label }}
              </el-radio-button>
            </el-radio-group>
        </div>
        <div class="kolContentTable" style="margin-top: 10px">
          <el-table
              height="650px"
              v-loading="articleTopLoading"
              :data="kolContentTableDataCopy"
              style="max-height: 650px; overflow: auto"
          >
            <el-table-column
                prop="brandName"
                align="center"
                width="100"
                label="品牌"
                fixed
            >
            </el-table-column>
            <el-table-column
                prop="productName"
                align="center"
                show-overflow-tooltip
                label="产品"
                width="160"
                fixed
            >
              <template #default="{ row }">
                {{
                  row.productName === null
                      ? "--"
                      : `${row.productName.replace(/,/g, "\n")}`
                }}
              </template>
            </el-table-column>
            <el-table-column
                prop="kolName"
                align="center"
                width="100"
                show-overflow-tooltip
                label="KOL名称"
                fixed
            >
            </el-table-column>
            <el-table-column
                prop="platform"
                width="130"
                align="center"
                fixed
                label="平台"
            >
              <template #header>
                <el-select v-model="selPlatform" placeholder="请选择">
                  <el-option
                      v-for="item in platform"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template #default="{ row }">
                {{ platformToZn(row.platform) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="tier"
                align="center"
                show-overflow-tooltip
                label="KOL级别"
            >
              <template #default="{ row }">
                {{
                  row.tier === 0
                      ? "KOC"
                      : row.tier === 1
                          ? "Tier1"
                          : row.tier === 2
                              ? "Tier2"
                              : row.tier === 3
                                  ? "Tier3"
                                  : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
                prop="kolFans"
                align="center"
                width="130"
                label="粉丝量"
            >
              <template #default="{ row }">
                {{ sliceDecimal(row.kolFans) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="taRatioPct"
                align="center"
                show-overflow-tooltip
                label="TA浓度%"
                width="100"
            >
              <template #default="{ row }">
                {{ row.taRatioPct === null ? "--" : row.taRatioPct }}
              </template>
            </el-table-column>

            <el-table-column
                width="150"
                prop="postDate"
                align="center"
                label="发文日期"
            >
            </el-table-column>
            <el-table-column
                prop="link"
                align="center"
                show-overflow-tooltip
                width="200"
                label="文章链接"
            >
              <template #default="{ row }">
                <a
                    v-if="row.title"
                    target="_blank"
                    style="color: #51a6f0"
                    :href="row.link"
                >{{ row.title }}</a
                >
                <a
                    target="_blank"
                    style="color: #51a6f0"
                    :href="row.link"
                    v-else
                >{{ row.link === null ? "--" : row.link }}</a
                >
              </template>
            </el-table-column>
            <el-table-column
                prop="exposure"
                width="130"
                align="center"
                label="阅读量"
            >
              <template #default="{ row }">
                {{ sliceDecimal(row.exposure) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="engagement"
                align="center"
                width="120"
                label="互动量"
            >
              <template #default="{ row }">
                {{ sliceDecimal(row.engagement) }}
              </template>
            </el-table-column>
            <el-table-column prop="waterPct" align="center" label="水量%">
              <template #default="{ row }">
                {{ row.waterPct === null ? "--" : row.waterPct }}
              </template>
            </el-table-column>
            <el-table-column
                prop="price"
                width="120"
                align="center"
                label="投放费用"
            >
              <template #default="{ row }">
                {{ row.price === null ? "--" : sliceDecimal(row.price) }}
              </template>
            </el-table-column>
            <el-table-column prop="cpm" align="center" label="CPM">
              <template #default="{ row }">
                {{ row.cpm === null ? "--" : "￥" + sliceDecimal(row.cpm) }}
              </template>
            </el-table-column>
            <el-table-column prop="cpe" align="center" label="CPE">
              <template #default="{ row }">
                {{ row.cpe === null ? "--" : "￥" + sliceDecimal(row.cpe) }}
              </template>
            </el-table-column>
            <el-table-column prop="taCpm" align="center" label="TA CPM">
              <template #default="{ row }">
                {{ row.taCpm === null ? "--" : "￥" + sliceDecimal(row.taCpm) }}
              </template>
            </el-table-column>
            <el-table-column prop="taCpe" align="center" label="TA CPE">
              <template #default="{ row }">
                {{ row.taCpe === null ? "--" : "￥" + sliceDecimal(row.taCpe) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog v-model="showTier" width="50%" append-to-body>
      <img class="imgClass" :src="require('./tier.png')" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import storage from "@/utils/storage";
import lineBar from "@/views/sov/sovCommonComponents/lineBar";
import legendCom from "@/views/sov/sovCommonComponents/legendCom";
import sovBrandCompareProfile from "./sovComponents/sovBrandCompareProfileData.vue";
import {
  getBrandComparisonOverview,
  getBrandComparisonDistribution,
  getBrandComparisonTrend,
  getBrandCateTrend,
  getBrandArticleTop,
  getBrandMonth,
  downloadGeneralView,
  downloadTopReport,
  getSovBrandCommparisonPersona,
  getSovBrandComparisonPlatformTa,
} from "@/api/sov/sovBrand";
import { getSovBrandAggMonth } from "@/api/sov/sovMain";
import {
  setStartTime,
  setEndTime,
  setStartWeekTime,
  setEndWeekTime,
  setStartMonthTime,
  setEndMonthTime,
} from "../sovCommonFunc";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      field: "pgc_num",
      segValue: "32_1",
      topSeg: "32_1",
      generalViewSeg: "32_1",
      // 活动时间
      timeRangeVal: "",
      durationList: [
        {
          value: "1mon",
          label: "近1个月",
        },
        {
          value: "3mon",
          label: "近3个月",
        },
        {
          value: "6mon",
          label: "近6个月",
        },
        {
          value: "12mon",
          label: "近12个月",
        },
        {
          value: "thisYear",
          label: "今年至今",
        },
        {
          value: "lastYear",
          label: "去年全年",
        },
      ],
      generalViewTableHeadClass: {
        "background-color": "#F6F8FA",
        "font-size": "14px",
      },
      generalViewTableData: [],
      taTableData: [],
      distributeTableData: [],
      kolContentTableData: [],
      expand: {
        expandAll: false,
        isExpand: false,
      },
      distribute: {
        radio: "percent",
      },
      distributeCard: {
        radio: "exposure",
      },
      // 声量 趋势 数据
      echartsType: "cost",
      echartsTime: "day",
      expansion: [],
      campaignFilter: [],
      originCampaignFilter: [],
      bNameList: [],
      campaignList: [],
      campaignListOption: [],
      echartLegend: [],
      echartxAxis: {
        data: [
          201905, 201906, 201907, 201908, 201909, 201910, 201911, 201912,
          202001, 202002,
        ],
        type: "category",
      },
      echartSeries: [
        {
          data: [
            73079874, 73584250, 55022549, 62489669, 54538919, 42380599,
            28357890, 42266074, 53824757, 38509705,
          ],
          name: "cam1",
          type: "line",
          smooth: true,
        },
      ],
      cateEchartSeries: [],
      echartColor: ["rgb(83, 186, 210)", "#e37893", "#daae3c"],
      defaultColors: [
        "#2c7d98",
        "#d1eef8",
        "#f0a3b6",
        "#a02947",
        "#06c1ff",
        "#65aeb1",
        "#bda29a",
        "#ff7c7c",
        "#ffd3d3",
        "#db7cae",
        "#d3baed",
        "#966767",
        "#4fe3ff",
        "#ffa86d",
        "#6797fe",
        "#6c80fe",
        "#ffb57e",
        "#4ebcb4",
      ],
      originData: [],
      originCateData: [],
      filterOriginDate: [],
      searchInput: "",
      brandRadio: "",
      // 表格loading
      distributeLoading: false,
      articleTopLoading: false,
      generalViewLoading: false,
      taLoading: false,
      platform: [
        {
          label: "全平台",
          value: "all",
        },
        {
          label: "微信",
          value: "wechat",
        },
        {
          label: "微博",
          value: "weibo",
        },
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "小红书",
          value: "xiaohongshu",
        },
        {
          label: "哔哩哔哩",
          value: "bilibili",
        },
      ],
      selPlatform: "all",
      cateFlag: false,
      showTier: false,
      num: 0,
      timeMonth: "",
      timeYear: "",
      type: "percent",
      yType: "exposure",
      page: 1,
      size: 20,
      total: 0,
      btnLoading: false,
      brandsGeneralViewLoad: false,
      kolContentTableDataCopy: [],
      xAxisData: [],
      arr: [],
      arr1: [],
      cityArr: [],
      incomeArr: [],
      ageLegend: [
        "18-28岁",
        "23-29岁",
        "30-34岁",
        "35-39岁",
        "40-44岁",
        "45-54岁",
        ">=55岁",
      ],
      genderLegend: ["男", "女"],
      cityLegend: ["一线城市", "二线城市", "三线城市", "四线城市"],
      incomeLegend: ["贫困", "低收入", "小康", "中产", "大众富裕", "富裕"],
      timeRangeArr: "",
      logo:[]
    };
  },
  props: {
    duration: {
      type: String,
      default: "",
    },
    brands: {
      type: String,
      default: "",
    },
    brandNameList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapState(["brandsSeg"]),
    brandNameLabelList() {
      return this.brandNameList.map((v) => v.label);
    },
    timeRange() {
      return this.timeRangeVal;
    },
    crowdListComputed() {
      return this.formatCrowdList(storage.getItem("corwdListStorage"));
    },
    // campaignListFilter() {
    //   return ;
    // },
  },
  mounted() {
    let doms = document.querySelector(
      ".kolContentTable .el-table__body-wrapper"
    );
    doms.addEventListener("scroll", this.func(this.scrollHandle, 1000));
    this.buildBrandList();
    this.lazyLoad();
    this.timeRangeVal = JSON.parse(this.duration);
    this.timeRangeArr = storage.getItem("timeRange");
    this.logo = storage.getItem('logo')
  },
  methods: {
    ...mapMutations({
      setBrandsSeg: "SET_BRANDSSEG",
    }),
    disabledDate(time) {
      if (this.timeRangeArr) {
        if (this.timeRangeArr.length > 1) {
          return (
            time.getTime() < new Date(this.timeRangeArr[0]).getTime() ||
            time.getTime() >
            new Date(
              this.timeRangeArr[this.timeRangeArr.length - 1]
            ).getTime()
          );
        } else {
          return true;
        }
      }
      // if (this.timeRangeArr) {
      //   if (this.timeRangeArr.length > 1) {
      //     return (
      //       time.getTime() <
      //         new Date(
      //           this.timeRangeArr[this.timeRangeArr.length - 1]
      //         ).getTime() ||
      //       time.getTime() > new Date(this.timeRangeArr[0]).getTime()
      //     );
      //   } else {
      //     return true;
      //   }
      // }
    },
    changeSeg(val) {
      this.setBrandsSeg(val);
      this.segValue = val;
      // this.getSovBrandComparisonPlatformTaFunc();
    },
    changeTopSeg(val) {
      this.setBrandsSeg(val);
    },
    formatCrowdList(list = []) {
      let tempList = [];
      list.forEach((v) => {
        if (v.key !== null) {
          tempList.push(v);
        }
      });
      return tempList;
    },
    async getSovBrandComparisonPlatformTaFunc() {
      this.taLoading = true;
      let res = await getSovBrandComparisonPlatformTa({
        id: this.brands,
        endMonth: this.timeRange[1],
        startMonth: this.timeRange[0],
        seg: this.brandsSeg,
      });
      if (res.code === 0) {
        res.data.forEach((v, i) => {
          v.id = i + 1;
          if (v.details && v.details.length !== 0 && v.name !== "total") {
            v.details.forEach((k) => {
              // k.name = k.tier;
              k.id = Math.random() * 2 + 1;
            });
          }
        });
        this.taTableData = res.data;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      this.taLoading = false;
    },
    async lazyLoad() {
      console.log("hahahaha");
      let res = await getSovBrandAggMonth();
      console.log(res);
      if (res.code === 0) {
        let temp = [];
        res.data.forEach((v) => {
          temp.push({
            value: v,
            label: v,
          });
        });
        this.durationList = [
          {
            value: "zhiding",
            label: "指定月",
            children: temp,
          },
          {
            value: "1mon",
            label: "近1个月",
          },
          {
            value: "3mon",
            label: "近3个月",
          },
          {
            value: "6mon",
            label: "近6个月",
          },
          {
            value: "12mon",
            label: "近12个月",
          },
          {
            value: "thisYear",
            label: "今年至今",
          },
          {
            value: "lastYear",
            label: "去年全年",
          },
        ];
      }
    },
    func(func, delay) {
      var timer = null;
      return () => {
        var args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            func.apply(this, args);
            timer = null;
          }, delay);
        }
      };
    },
    scrollHandle() {
      console.log(
        document.querySelector(".kolContentTable .el-table__body-wrapper")
          .scrollTop
      );
      console.log(
        document.querySelector(".kolContentTable .el-table__body-wrapper")
          .scrollHeight
      );
      let timeOut = null;
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        if (
          document.querySelector(".kolContentTable .el-table__body-wrapper")
            .scrollTop +
          670 >
          document.querySelector(".kolContentTable .el-table__body-wrapper")
            .scrollHeight
        ) {
          if (this.page * this.size > this.total) {
            return;
          }
          this.page++;
          this.getBrandArticleTop();
        }
      }, 400);
    },
    async getBrandMonth() {
      let res = await getBrandMonth();
      console.log(res);
      if (res.code === 0) {
        this.timeMonth = res.data.split("-")[1];
        this.timeYear = res.data.split("-")[0];
        console.log(this.timeMonth, this.timeYear);
      } else {
        this.$message.error("获取时间失败，请联系管理员!");
      }
    },
    buildBrandList() {
      this.campaignList = this.brandNameList;
      this.brandNameList.forEach((v) => {
        if (v.label !== "全部") {
          this.campaignListOption.push(v);
        }
      });
      let ids = [];
      this.campaignList.forEach((v) => {
        ids.push(v.value);
      });
      this.campaignList.unshift({
        label: "全部",
        value: ids.join(","),
      });
      this.brandRadio = this.campaignList[0].value;
      this.campaignList.forEach((v) => {
        if (v.label !== "全部") {
          this.campaignFilter.push(v.value);
        }
      });
      this.originCampaignFilter = this.campaignFilter;
    },
    async init() {
      await this.getBrandMonth();
      this.getBrandComparisonOverview();
      this.getBrandComparisonDistribution();
      this.getBrandComparisonTrend();
      this.getSovBrandCommparisonPersona();
      this.getSovBrandComparisonPlatformTaFunc();
    },
    async getSovBrandCommparisonPersona() {
      this.arr = [];
      this.arr1 = [];
      this.cityArr = [];
      this.incomeArr = [];
      let res = await getSovBrandCommparisonPersona({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.brands,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.xAxisData = Object.keys(res.data);
        // 准备用数据结构
        const seriesData = {
          age: [],
          city_tier: [],
          gender: [],
          income: [],
        }
        const label = ['age', 'city_tier', 'gender', 'income']
        const extractData = (data, key) => {
          const itemData = []
          this.xAxisData.forEach((brand, index) => {
            itemData[index] = {}
            itemData[index][key] = []
            const brandData = [];
            const categoryData = data[brand][key];
            const categories = Object.keys(categoryData).sort();
            categories.forEach(category => {
              brandData.push(categoryData[category].pct);
            });
            itemData[index][key] = [...brandData]
          });
          return itemData
        }

        for (let i = 0; i < label.length; i++) {
          seriesData[label[i]] = extractData(res.data, label[i])
        }


        const reorganizeData = (data) => {
          const result = {};
          // 对每个属性进行处理
          for (const [key, value] of Object.entries(data)) {
            result[key] = [];

            // 遍历每个属性下的所有数组
            value.forEach((item) => {
              item[key].forEach((element, index) => {
                // 确保result[key][index]是一个数组
                if (!Array.isArray(result[key][index])) {
                  result[key][index] = [];
                }
                // 将元素添加到对应的数组中
                result[key][index].push(element);
              });
            });
          }

          return result;
        }

        const organizedData = reorganizeData(seriesData);
        this.arr = organizedData.age
        this.arr1 = organizedData.gender
        this.cityArr = organizedData.city_tier
        this.incomeArr = organizedData.income
        // let objs = Object.values(res.data);
        // console.log(objs);
        // objs.forEach((v, i, self) => {
        //   if (i + 1 < self.length && self.length === 2) {
        //     for (let k in self[i].age) {
        //       for (let j in self[i + 1].age) {
        //         if (k === j) {
        //           this.arr.push([self[i].age[k].pct, self[i + 1].age[j].pct]);
        //         }
        //       }
        //     }
        //   } else if (self.length === 3 && i + 2 < self.length) {
        //     console.log(self);
        //     for (let k in self[i].age) {
        //       for (let j in self[i + 1].age) {
        //         for (let l in self[i + 2].age) {
        //           if (k === j && j === l) {
        //             this.arr.push([
        //               self[i].age[k].pct,
        //               self[i + 1].age[j].pct,
        //               self[i + 2].age[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   } else if (self.length === 4 && i + 3 < self.length) {
        //     console.log(self);
        //     for (let k in self[i].age) {
        //       for (let j in self[i + 1].age) {
        //         for (let l in self[i + 2].age) {
        //           if (k === j && j === l) {
        //             this.arr.push([
        //               self[i].age[k].pct,
        //               self[i + 1].age[j].pct,
        //               self[i + 2].age[l].pct,
        //               self[i + 3].age[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   } else if (self.length === 5 && i + 4 < self.length) {
        //     console.log(self);
        //     for (let k in self[i].age) {
        //       for (let j in self[i + 1].age) {
        //         for (let l in self[i + 2].age) {
        //           if (k === j && j === l) {
        //             this.arr.push([
        //               self[i].age[k].pct,
        //               self[i + 1].age[j].pct,
        //               self[i + 2].age[l].pct,
        //               self[i + 3].age[l].pct,
        //               self[i + 4].age[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        // objs.forEach((v, i, self) => {
        //   if (i + 1 < self.length && self.length === 2) {
        //     for (let k in self[i].gender) {
        //       for (let j in self[i + 1].gender) {
        //         if (k === j) {
        //           this.arr1.push([
        //             self[i].gender[k].pct,
        //             self[i + 1].gender[j].pct,
        //           ]);
        //         }
        //       }
        //     }
        //   } else if (self.length === 3 && i + 2 < self.length) {
        //     console.log(self);
        //     for (let k in self[i].gender) {
        //       for (let j in self[i + 1].gender) {
        //         for (let l in self[i + 2].gender) {
        //           if (k === j && j === l) {
        //             this.arr1.push([
        //               self[i].gender[k].pct,
        //               self[i + 1].gender[j].pct,
        //               self[i + 2].gender[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   } else if (self.length === 4 && i + 3 < self.length) {
        //     console.log(self);
        //     for (let k in self[i].gender) {
        //       for (let j in self[i + 1].gender) {
        //         for (let l in self[i + 2].gender) {
        //           if (k === j && j === l) {
        //             this.arr1.push([
        //               self[i].gender[k].pct,
        //               self[i + 1].gender[j].pct,
        //               self[i + 2].gender[l].pct,
        //               self[i + 3].gender[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   } else if (self.length === 5 && i + 4 < self.length) {
        //     console.log(self);
        //     for (let k in self[i].gender) {
        //       for (let j in self[i + 1].gender) {
        //         for (let l in self[i + 2].gender) {
        //           if (k === j && j === l) {
        //             this.arr1.push([
        //               self[i].gender[k].pct,
        //               self[i + 1].gender[j].pct,
        //               self[i + 2].gender[l].pct,
        //               self[i + 3].gender[l].pct,
        //               self[i + 4].gender[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        // objs.forEach((v, i, self) => {
        //   if (i + 1 < self.length && self.length === 2) {
        //     for (let k in self[i].city_tier) {
        //       for (let j in self[i + 1].city_tier) {
        //         if (k === j) {
        //           this.cityArr.push([
        //             self[i].city_tier[k].pct,
        //             self[i + 1].city_tier[j].pct,
        //           ]);
        //         }
        //       }
        //     }
        //   } else if (self.length === 3 && i + 2 < self.length) {
        //     for (let k in self[i].city_tier) {
        //       for (let j in self[i + 1].city_tier) {
        //         for (let l in self[i + 2].city_tier) {
        //           if (k === j && j === l) {
        //             this.cityArr.push([
        //               self[i].city_tier[k].pct,
        //               self[i + 1].city_tier[j].pct,
        //               self[i + 2].city_tier[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   } else if (self.length === 4 && i + 3 < self.length) {
        //     for (let k in self[i].city_tier) {
        //       for (let j in self[i + 1].city_tier) {
        //         for (let l in self[i + 2].city_tier) {
        //           if (k === j && j === l) {
        //             this.cityArr.push([
        //               self[i].city_tier[k].pct,
        //               self[i + 1].city_tier[j].pct,
        //               self[i + 2].city_tier[l].pct,
        //               self[i + 3].city_tier[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   } else if (self.length === 5 && i + 4 < self.length) {
        //     for (let k in self[i].city_tier) {
        //       for (let j in self[i + 1].city_tier) {
        //         for (let l in self[i + 2].city_tier) {
        //           if (k === j && j === l) {
        //             this.cityArr.push([
        //               self[i].city_tier[k].pct,
        //               self[i + 1].city_tier[j].pct,
        //               self[i + 2].city_tier[l].pct,
        //               self[i + 3].city_tier[l].pct,
        //               self[i + 4].city_tier[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        // objs.forEach((v, i, self) => {
        //   if (i + 1 < self.length && self.length === 2) {
        //     for (let k in self[i].income) {
        //       for (let j in self[i + 1].income) {
        //         if (k === j) {
        //           this.incomeArr.push([
        //             self[i].income[k].pct,
        //             self[i + 1].income[j].pct,
        //           ]);
        //         }
        //       }
        //     }
        //   } else if (self.length === 3 && i + 2 < self.length) {
        //     for (let k in self[i].income) {
        //       for (let j in self[i + 1].income) {
        //         for (let l in self[i + 2].income) {
        //           if (k === j && j === l) {
        //             this.incomeArr.push([
        //               self[i].income[k].pct,
        //               self[i + 1].income[j].pct,
        //               self[i + 2].income[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   } else if (self.length === 4 && i + 3 < self.length) {
        //     for (let k in self[i].income) {
        //       for (let j in self[i + 1].income) {
        //         for (let l in self[i + 2].income) {
        //           if (k === j && j === l) {
        //             this.incomeArr.push([
        //               self[i].income[k].pct,
        //               self[i + 1].income[j].pct,
        //               self[i + 2].income[l].pct,
        //               self[i + 3].income[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   } else if (self.length === 5 && i + 4 < self.length) {
        //     for (let k in self[i].income) {
        //       for (let j in self[i + 1].income) {
        //         for (let l in self[i + 2].income) {
        //           if (k === j && j === l) {
        //             this.incomeArr.push([
        //               self[i].income[k].pct,
        //               self[i + 1].income[j].pct,
        //               self[i + 2].income[l].pct,
        //               self[i + 3].income[l].pct,
        //               self[i + 4].income[l].pct,
        //             ]);
        //           }
        //         }
        //       }
        //     }
        //   }
        // });
        // this.arr = [...this.arr];
        // this.arr1 = [...this.arr1];
        // this.cityArr = [...this.cityArr];
        // this.incomeArr = [...this.incomeArr];
        // console.log(this.arr1);
      } else {
        this.$message.error("请求数据失败，请联系管理员!");
      }
    },
    formatProfileData(arr) {
      console.log(arr);
    },
    async getBrandComparisonOverview() {
      this.generalViewLoading = true;
      let res = await getBrandComparisonOverview({
        // duration: this.timeRange,
        id: this.brands,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.generalViewLoading = false;
        this.formatNull(res.data);
        // 获取对比品牌
        this.generalViewTableData = res.data;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    async getBrandComparisonDistribution() {
      this.distributeLoading = true;
      let res = await getBrandComparisonDistribution({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.brands,
        type: this.distribute.radio,
        field: this.field,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        console.log(res);
        // this.formatNull(res.data);
        res.data.forEach((v, i) => {
          v.id = i + 1;
          if (v.details && v.details.length !== 0) {
            v.details.forEach((k) => {
              k.id = Math.random() * 2 + 1;
            });
            this.expansion[i] = false;
          } else {
            this.expansion[i] = null;
          }
          console.log(v.details);
          v.details && v.details.length
            ? (this.expansion[i] = false)
            : (this.expansion[i] = null);
        });
        console.log(this.distributeTableData);
        this.distributeTableData = res.data;
        console.log(this.distributeTableData);
        // 全部收起
        this.distributeTableData.forEach((item) => {
          this.$refs.treeTable.toggleRowExpansion(item, false);
        });
        this.distributeLoading = false;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    async getBrandComparisonTrend() {
      let res = await getBrandComparisonTrend({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.campaignFilter.join(","),
        xType: this.echartsTime,
        yType: this.distributeCard.radio,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        console.log(res.data);
        this.originData = [].concat(res.data);
        this.filterOriginDate = [].concat(res.data);
        this.initTrendData(res.data);
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    initTrendData(data) {
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      console.log(data);
      data.forEach((campaign, i) => {
        this.echartLegend.push({
          name: campaign.name,
          color: this.echartColor.concat(this.defaultColors)[i],
          index: Math.random() * 2 * Math.random(),
        });
        startTimeArr.push(campaign.trend[0].xAxis);
        const lastIndex = campaign.trend.length - 1;
        endTimeArr.push(campaign.trend[lastIndex].xAxis);
      });
      console.log(startTimeArr, endTimeArr);
      if (this.echartsTime === "day") {
        // day
        startTimeArr.push(
          setStartTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatDayDate(startTimeArr, endTimeArr);
      } else if (this.echartsTime === "week") {
        // week
        startTimeArr.push(
          setStartWeekTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndWeekTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatWeekDate(startTimeArr, endTimeArr);
      } else {
        // month
        startTimeArr.push(
          setStartMonthTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndMonthTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatMonthDate(startTimeArr, endTimeArr);
      }
    },
    initCateTrendData(data) {
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      const cateInfoListAll = [];
      console.log(data);
      data.forEach((v) => {
        v.cateInfoList.forEach((k) => {
          k.brandName = v.brandName;
          cateInfoListAll.push(k);
        });
      });
      console.log(cateInfoListAll);
      cateInfoListAll.forEach((v, i) => {
        this.echartLegend.push({
          name: `${v.brandName}: ${v.cateName}`,
          color: this.echartColor.concat(this.defaultColors)[i],
          index: Math.random() * 2 * Math.random(),
        });
      });
      data.forEach((campaign) => {
        campaign.cateInfoList.forEach((v) => {
          startTimeArr.push(v.trend[0].xAxis);
          const lastIndex = v.trend.length - 1;
          endTimeArr.push(v.trend[lastIndex].xAxis);
        });
      });
      console.log(startTimeArr, endTimeArr);
      if (this.echartsTime === "day") {
        // day
        startTimeArr.push(
          setStartTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatDayDate(startTimeArr, endTimeArr, "cate");
      } else if (this.echartsTime === "week") {
        // week
        startTimeArr.push(
          setStartWeekTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndWeekTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatWeekDate(startTimeArr, endTimeArr, "cate");
      } else {
        // month
        startTimeArr.push(
          setStartMonthTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndMonthTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatMonthDate(startTimeArr, endTimeArr, "cate");
      }
    },
    formatDayDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getDayAllDate(startTime, endTime, type);
    },
    getDayAllDate(start, end, type) {
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      console.log(db);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      console.log(de);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp; stamp <= stampDe; stamp += oneDay) {
        dateArr.push(this.timeStampToDate(stamp));
      }
      console.log(dateArr);
      this.echartxAxis.data = dateArr;
      if (type) {
        this.getCateDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    formatWeekDate(startTimeArr, endTimeArr, type) {
      const nStartTimeArr = startTimeArr.map((time) => time.slice(0, 10));
      const nEndTimeArr = endTimeArr.map((time) => time.slice(-10));
      console.log(nStartTimeArr, nEndTimeArr);
      const startTimeStamp = nStartTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      console.log(startTime);
      const endTimeStamp = nEndTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      console.log(endTime);
      this.getWeekAllDate(startTime, endTime, type);
    },
    getWeekAllDate(start, end, type) {
      console.log(type);
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      let stampArr = [];
      for (stamp; stamp <= stampDe; stamp += oneWeek) {
        stampArr.push(stamp);
      }
      console.log(stampArr);
      const sixDay = 6 * 24 * 60 * 60 * 1000;
      let dateArr = [];
      dateArr = stampArr.map((stamp) => {
        const weekLastDayStamp = stamp + sixDay;
        return (
          this.timeStampToDate(stamp) +
          " ~ " +
          this.timeStampToDate(weekLastDayStamp)
        );
      });
      console.log(dateArr);
      this.echartxAxis.data = dateArr;
      if (type) {
        this.getCateDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    formatMonthDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      console.log(startTime);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      console.log(endTime);
      this.getMonthAllDate(startTime, endTime, type);
    },
    getMonthAllDate(start, end, type) {
      let startArr = start.split("-");
      let startYear = startArr[0] * 1;
      let startMonth = startArr[1] * 1;
      let endArr = end.split("-");
      let endYear = endArr[0] * 1;
      let endMonth = endArr[1] * 1;
      let dateArr = [];
      if (startYear < endYear) {
        for (startMonth; startMonth <= 12; startMonth++) {
          const tempStr =
            startYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
          if (startMonth === 12 && startYear + 1 < endYear) {
            startYear++;
            startMonth = 0;
          }
        }
        for (let i = 1; i <= endMonth; i++) {
          const tempStr = endYear + "-" + (i < 10 ? "0" + i : i);
          dateArr.push(tempStr);
        }
      } else {
        for (startMonth; startMonth <= endMonth; startMonth++) {
          const tempStr =
            endYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
        }
      }
      console.log(dateArr);
      this.echartxAxis.data = dateArr;
      if (type) {
        this.getCateDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    timeStampToDate(timestamp) {
      const date = new Date(timestamp);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },
    getDataFromOrigin() {
      console.log(this.filterOriginDate);
      this.echartSeries = this.filterOriginDate.map((campaign) => {
        const seriesItem = {
          data: [],
          name: "",
          type: "line",
          smooth: true,
        };
        seriesItem.name = campaign.name;
        // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
        const startTimeIndex = this.echartxAxis.data.indexOf(
          campaign.trend[0].xAxis
        );
        const endTimeIndex = this.echartxAxis.data.indexOf(
          campaign.trend[campaign.trend.length - 1].xAxis
        );
        console.log(startTimeIndex, endTimeIndex);
        const campaignxAxis = this.echartxAxis.data.slice(
          startTimeIndex,
          endTimeIndex + 1
        );
        seriesItem.data = this.echartxAxis.data.map((xAxis) => {
          let data = 0;
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign.trend.find((item) => item.xAxis === xAxis);
            trend ? (data = trend.yAxis) : (data = 0);
          }
          return data;
        });
        return seriesItem;
      });
      console.log(this.echartSeries);
    },
    getCateDataFromOrigin() {
      let res = [];
      this.echartSeries = [];
      console.log(this.filterOriginDate);
      console.log(this.catefilterOriginDate);
      this.catefilterOriginDate.forEach((campaign) => {
        campaign.cateInfoList.forEach((v) => {
          const seriesItem = {
            data: [],
            name: "",
            type: "line",
            smooth: true,
          };
          seriesItem.name = `${v.brandName}: ${v.cateName}`;
          // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
          const startTimeIndex = this.echartxAxis.data.indexOf(
            v.trend[0].xAxis
          );
          const endTimeIndex = this.echartxAxis.data.indexOf(
            v.trend[v.trend.length - 1].xAxis
          );
          const campaignxAxis = this.echartxAxis.data.slice(
            startTimeIndex,
            endTimeIndex + 1
          );
          seriesItem.data = this.echartxAxis.data.map((xAxis) => {
            let data = 0;
            if (campaignxAxis.includes(xAxis)) {
              const trend = v.trend.find((item) => item.xAxis === xAxis);
              trend ? (data = trend.yAxis) : (data = 0);
            }
            return data;
          });
          res.push(seriesItem);
        });
      });
      this.echartSeries = res;
    },
    async getBrandTrend() {
      this.cateFlag = !this.cateFlag;
      this.campaignFilter = this.originCampaignFilter;
      this.getBrandComparisonTrend();
    },
    setBrandCateTrend() {
      this.cateFlag = !this.cateFlag;
      this.campaignFilter = this.originCampaignFilter;
      this.getBrandCateTrend();
    },
    async getBrandCateTrend() {
      let res = await getBrandCateTrend({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.campaignFilter.join(","),
        xType: this.echartsTime,
        yType: "exposure",
      });
      console.log(res.data);
      if (res.code === 0) {
        this.catefilterOriginDate = [].concat(res.data);
        this.originCateData = [].concat(res.data);
        this.initCateTrendData(res.data);
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    async getBrandArticleTop() {
      this.articleTopLoading = true;
      let res = await getBrandArticleTop({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.brandRadio,
        searchWord: this.searchInput,
        platform: this.selPlatform,
        sortColumn: "exposure",
        sortDirection: "desc",
        page: this.page,
        size: 20,
        seg: this.brandsSeg,
        // aggMonth: this.timeRange,
      });
      if (res.code === 0) {
        this.total = res.data.total;
        this.kolContentTableData = res.data.adsTopArticles;
        this.kolContentTableDataCopy.push(...this.kolContentTableData);
        console.log(this.kolContentTableDataCopy);
        this.articleTopLoading = false;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    handleSearch() {
      this.searchBtn();
    },
    searchBtn() {
      this.page = 1;
      this.kolContentTableDataCopy = [];
      this.getBrandArticleTop();
    },
    expandChange(row, expanded) {
      let doms = document.querySelectorAll(".el-table__placeholder");
      let that = this;
      doms.forEach((v) => {
        v.classList.add("el-icon-warning-outline");
        v.onclick = function (e) {
          that.showTier = true;
          e.stopPropagation();
        };
      });
      const index = row.id - 1;
      this.expansion[index] = expanded;
    },
    handleExpansion() {
      const expanded = this.expansion.includes(false);
      this.distributeTableData.forEach((item) => {
        this.$refs.treeTable.toggleRowExpansion(item, expanded);
      });
    },
    downloadGeneralView() {
      const params = {
        // duration: this.timeRange,
        id: this.campaignFilter.join(","),
        // aggMonth: this.timeRange[1],
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        seg: this.brandsSeg,
      };
      this.brandsGeneralViewLoad = true;
      downloadGeneralView(params, "品牌声量概览.xlsx")
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
          this.brandsGeneralViewLoad = false;
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    downContent() {
      const params = {
        // duration: this.timeRange,
        id: this.brands,
        platform: this.selPlatform,
        searchWord: this.searchInput,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        seg: this.brandsSeg,
        // aggMonth: this.timeRange[1],
      };
      this.btnLoading = true;
      this.$message({
        type: "info",
        message: "文件正在解析，请耐心等待",
      });
      downloadTopReport(params, "KOL投放内容.xlsx")
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    handleFilter() {
      const campaignFilterTemp = [];
      this.campaignList.forEach((v) => {
        this.campaignFilter.forEach((k) => {
          if (k === v.value) {
            campaignFilterTemp.push(v.label);
          }
        });
      });
      this.filterOriginDate = campaignFilterTemp.map((v) => {
        if (!this.cateFlag) {
          const tempArr = this.originData.filter(
            (compaign) => compaign.name === v
          );
          return tempArr[0];
        } else {
          const tempArr = this.originCateData.filter(
            (compaign) => compaign.brandName === v
          );
          return tempArr[0];
        }
      });
      console.log(this.filterOriginDate);
      if (!this.cateFlag) {
        this.initTrendData(this.filterOriginDate);
      } else {
        this.catefilterOriginDate = this.filterOriginDate;
        this.initCateTrendData(this.filterOriginDate);
      }
    },
    forBack() {
      this.$router.push({ name: "sovBrandList" });
    },
    rowStyleFunc() {
      return "otherStyle";
    },
    distributeRowStyleFunc() {
      return "rowStyle";
    },
  },
  watch: {
    brandsSeg(val) {
      this.segValue = val;
      this.kolContentTableDataCopy = [];
      this.getSovBrandComparisonPlatformTaFunc();
      this.getBrandArticleTop();
    },
    field(val) {
      if (val) {
        this.getBrandComparisonDistribution();
      }
    },
    timeRangeVal() {
      console.log(1);
      this.init();
      this.getBrandArticleTop();
    },
    "distribute.radio"() {
      this.getBrandComparisonDistribution();
    },
    "distributeCard.radio"() {
      this.getBrandComparisonTrend();
    },
    echartsTime() {
      if (!this.cateFlag) {
        this.getBrandComparisonTrend();
      } else {
        this.getBrandCateTrend();
      }
    },
    brandRadio() {
      this.num++;
      if (this.num !== 1) {
        this.kolContentTableDataCopy = [];
        this.page = 1;
        this.getBrandArticleTop();
      }
    },
    selPlatform() {
      this.kolContentTableDataCopy = [];
      this.page = 1;
      this.getBrandArticleTop();
    },
  },
  components: {
    lineBar,
    legendCom,
    sovBrandCompareProfile,
  },
};
</script>

<style lang="scss">
.brandComWrap {
  .el-table .cell.el-tooltip {
    white-space: pre;
  }
  .filter .el-date-editor .el-range-separator {
    width: 9%;
  }
  .selectClass {
    .el-scrollbar__view.el-select-dropdown__list {
      margin-top: 5px;
    }

    .el-select__caret.el-input__icon.el-icon-arrow-up {
      line-height: 28px;
    }

    font-size: 14px;

    .el-input__inner {
      width: 250px;
      height: 28px;
    }

    .el-input--suffix {
      height: 35px;
      line-height: 35px;
    }
  }

  .firstRowStyle {
    td div {
      font-size: 18px;
      color: #393a4d;
      font-weight: 400;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  .rowStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  //.el-radio__input.is-checked .el-radio__inner {
  //  background-color: #fff;
  //  border-color: #dda634;
  //}
  //
  //.el-radio__input.is-checked + .el-radio__label {
  //  color: #dda634;
  //}
  //
  //.el-radio__inner:hover {
  //  border-color: #dda634;
  //}

  .el-radio__inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
  }

  //.el-radio__inner::after {
  //  width: 10px;
  //  height: 10px;
  //  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  //}

  .el-radio {
    margin-right: 20px;
  }

  .distributeTable
    .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
  }

  .el-table__expanded-cell[class*="cell"] {
    background-color: #f9fcff;
    padding: 0;
    padding-left: 90px;
  }

  //.is-active {
  //  .el-radio-button__inner {
  //    background-color: #e09a29 !important;
  //    border-color: #e09a29 !important;
  //    color: #fff !important;
  //  }
  //}
  //
  //.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  //  box-shadow: -1px 0 0 0 #e09a29;
  //}
  //
  //.el-radio-button {
  //  .el-radio-button__inner {
  //    background-color: #f8f9fe;
  //    color: #979797;
  //  }
  //}

  .rightWrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    .segClass {
      line-height: normal !important;
      .el-input__inner {
        height: 28px !important;
      }
    }
    .el-input {
      .el-input__inner {
        height: 100%;
      }
    }
  }

  .kolContentTable {
    .el-input--suffix .el-input__inner {
      height: 30px;
    }

    .el-input__suffix {
      top: 5px;
    }

    .el-select .el-input__suffix {
      top: -4px;
    }

    .el-input--suffix .el-input__icon {
      line-height: 30px;
    }
  }

  .distribute {
    .el-table [class*="el-table__row--level"] .el-table__expand-icon {
      margin-bottom: 3px;
    }
  }

  .distribute thead .is-left .cell {
    margin-left: 20px;
  }
  .ta thead .is-left .cell {
    margin-left: 20px;
  }

  .el-table__placeholder {
    z-index: 1000;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
// font-size: 16px;
//     position: relative;
//     top: -10px;
//     background-color: #fff;
//     height: 62px;
//     line-height: 60px;
//     border: 1px solid #ebebeb;
//     border-radius: 10px;
//     padding: 0 20px;
//     color: #11263c;
//     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
.filterBoxFixed {
  width:20%;
  position: sticky;
  top:0;
  height: 100%;
}
.brandComWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap:20px;
  // height: 100%;

  .publicStyle {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .publicTitleStyle {
      padding:20px 20px 0 20px;
      font-size: 20px;
      color: #4f4f4f;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .downBtn {
      margin-right: 20px;
      background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      padding: 0 10px 1px;

      .downIconClass {
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }

  .btnPublic {
    display: inline-block;
    height: 30px;
    text-align: center;
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
    border-radius: 4px;
    color: #fff;
  }
  .btnPublicBtn {
    // width: 72px !important;
    vertical-align: top;
    height: 30px;
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
    border-radius: 4px;
    color: #fff;
    margin-left: 30px;
  }

  .distribute {
    margin-bottom: 20px;
  }

  .container {
    font-size: 16px;
    position: relative;
    top: -10px;
    // background-color: #fff;
    // height: 62px;
    // line-height: 60px;
    border: 1px solid #ebebeb;
    // border-radius: 10px;
    // padding: 0 20px;
    color: #11263c;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .sovTitle {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 0 20px;
      border-radius: 10px;

      .filter {
        flex: 1;
        display: flex;
        align-items: center;

        .brandText {
          line-height: 59px;
          color: #11263c;

          span {
            padding: 0 10px;
            border-right: 1px solid #d4d0db;
          }

          & :first-child {
            padding-left: 0;
          }

          & :last-child {
            padding-right: 0;
            border-right: none;
          }
        }
      }

      .returnBtn {
        color: #fff;
        width: 74px;
        padding: 0;
        background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
        border-radius: 4px;
      }
    }
  }

  .distributeTable {
    margin-top: 10px;

    .paddingClass {
      display: flex;
      height: 40px;
      line-height: 40px;

      .spanBox {
        flex: 1;
        display: flex;
        justify-content: space-around;
      }

      span {
        display: inline-block;
      }
    }
  }

  .btnGroupWrap {
    text-align: right;
    margin-right: 20px;

    .spandAll {
      width: 92px;
      height: 28px;
      line-height: 28px;
      text-align: left;
      padding-left: 8px;
      display: inline-block;
      background-color: #f4f3f3;
      color: #8c98a5;
      border-radius: 4px;
      cursor: pointer;

      .expandAllIclass {
        position: relative;
        left: 4px;
        top: -1px;
        transition: transform 0.2s ease-in-out;
      }

      .rotate {
        transform: rotate(-90deg);
      }
    }

    //.isExpandClass {
    //  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    //  color: #fff;
    //}
  }

  .item_title {
    font-size: 20px;
    margin-bottom: 20px;
    color: #4f4f4f;
  }

  .profileWrap {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-around;
    div {
      width: 47%;
      height: 100%;
    }
  }

  .item_square_content {
    width: 280px;
    height: 190px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 24px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .space_between_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icon_svg {
      font-size: 46px;
    }

    .line_svg {
      width: 100%;
      margin-bottom: 4px;
    }

    .big_text {
      font-size: 24px;
    }

    .normal_text {
      font-size: 20px;
    }

    .gray_text {
      color: #aeaaaa;
    }
  }

  .showCategorty {
    display: inline-block;
    width: 126px;
    height: 28px;
    line-height: 28px;
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
    border-radius: 4px;
    padding-left: 10px;
    color: #fff;
    cursor: pointer;

    .noteClass {
      position: relative;
      left: 8px;
      color: #fff;
      font-size: 16px;
      top: -1px;
    }
  }
  .searchText {
    font-size: 12px;
    flex-shrink: 0;
    color: #11263c;
  }

  .btnSearch {
    cursor: pointer !important;
    width: 64px;
  }
  .headBox {
    height: 40px;
    padding: 0 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .segClass {
      height: 40px;
      line-height: 40px;
      margin-right: 15px;
    }


  }
  .btnDown {
    cursor: pointer;
    width: 64px;
    text-align: left;
    padding-left: 10px;
    margin-left: 30px;

    .downIconClass {
      font-size: 16px;
      position: relative;
      top: -1px;
      left: 15px;
    }


    .btnPublicBtn {
      .downIconClass {
        font-size: 16px;
        position: relative;
        top: 1px;
        left: 10px;
      }
    }

    .el-input {
      width: 123px;
      height: 28px;
      border-radius: 5px;
      margin-left: 20px;
      margin-right: 10px;
      line-height: 28px;

      .el-input__inner {
        height: 100%;
      }
    }
  }
}

.iconStyle {
  position: absolute;
  left: -3px;
  top: 50%;
  transform: translateY(-40%);
  font-size: 16px;
}
//.riliClass {
//  margin-right: 5px;
//  fill: #e09a29;
//}

.imgClass {
  width: 100%;
  height: 100%;
  background-size: contain;
}
.left_block{
  background: #fff;
  border-radius: 20px;
  height:100%;
  width:100%;
  .title_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:67px;
    border-bottom:1px solid #EDEDED;
    padding:0 20px;
    p{
      font-weight: 500;
      font-size:20px;
      display: flex;
      align-items: center;
    }
  }
  .info_block{
    margin:0 20px;
    border-bottom: 1px solid #ededed;
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    .logo_block{
      width:35%;
      aspect-ratio: 1/1;
      box-shadow: 0px 0px 8.4px 0px #0000001F;
      img{
        width: 100%;
        height:100%;
      }
    }
    p{
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
    }
  }
  .date_block{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding:20px;
    span{
      flex-shrink: 0;
    }
  }
}
</style>
